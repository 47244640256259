import React from 'react'
import VisibilitySensor from "react-visibility-sensor";
import { animateStyles } from '../utils'

const BackgroundTriangleAndTitle = ({position, title}) => {
  const renderTriangle = () => {
    if (position === 'left') {
     return <div className="background-triangle-left"></div>
    }
    if (position === 'right') {
      return <div className="background-triangle-right"></div>
    }
    if (position === 'whole') {
      return <div className="background-triangle-whole"></div>
    }
    if (position === 'top-right') {
      return <div className="background-triangle-top-right"></div>
    }
    if (position === 'top-left') {
      return <div className="background-triangle-top-left"></div>
    }
    return null;
  }

  return (
    <div className="background-container full-width">
      { renderTriangle() }
      <VisibilitySensor partialVisibility>
        {({ isVisible }) => (
          <div style={isVisible ? animateStyles['fadeIn'].finish : animateStyles['fadeIn'].start} className="background-title">{title}</div>
        )}
      </VisibilitySensor>
    </div>
  );
};

export default BackgroundTriangleAndTitle;